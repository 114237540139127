import React, { ReactElement } from 'react'
import Layout from '../container/layout'
import { Banner, Forms } from '../components/rsvp/'

const RsvpPage: React.FC = (): ReactElement => (
  <Layout>
    <Banner />
    <Forms />
  </Layout>
)

export default RsvpPage
